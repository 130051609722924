// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-ajanvaraus-js": () => import("../src/pages/ajanvaraus.js" /* webpackChunkName: "component---src-pages-ajanvaraus-js" */),
  "component---src-pages-esittely-js": () => import("../src/pages/esittely.js" /* webpackChunkName: "component---src-pages-esittely-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-js": () => import("../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-opiskelijat-js": () => import("../src/pages/opiskelijat.js" /* webpackChunkName: "component---src-pages-opiskelijat-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("../src/pages/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */)
}

